import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
// import { useApi, configApiRef } from '@backstage/core-plugin-api';

const CostComponent: React.FC = () => {
  const entity = useEntity().entity;
  const namespace = entity.metadata.annotations?.['grafana/namespace'];
  // const config = useApi(configApiRef);
  // const url = config.getString('opencost.grafanaDashboard')
  const url = "https://grafana.ovh.alebox.xyz/d/d6dae90d-9ed5-4a9d-8a0e-38b3e0e340af/cost-by-namespace?orgId=1&var-DS_PROMETHEUS_DATASOURCE=mimir-1&kiosk&var-namespace=";
  const grafanaOpenCostDashboardUrl = `${url}${namespace}`;

  return (
    namespace ? <iframe  title={grafanaOpenCostDashboardUrl} src={grafanaOpenCostDashboardUrl} width="100%" height="100%" referrerPolicy="strict-origin-when-cross-origin" frameBorder="0" /> : null
  );
};

export default CostComponent;
